import { useEffect, useState } from 'react';

// eslint-disable-next-line import/no-anonymous-default-export
export default (s: number) => {
  const [seconds, setSeconds] = useState<number>(s);
  useEffect(() => {
    let timer = setTimeout(() => {
      if (seconds > 0) {
        setSeconds(seconds - 1);
      }
      clearTimeout(timer);
    }, 1000);
  }, [seconds]);

  return [seconds, setSeconds];
};
