/* eslint-disable @next/next/no-img-element */
/* eslint-disable react/display-name */
/* eslint-disable import/no-anonymous-default-export */
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { WKButton, WKModal } from '@wk/components';
import useUser from '@/store/useUser';
import useCount from './useCount';
import cls from 'classnames';
import { commonEmails } from './constant';

import style from './style.module.scss';
import { i18n, useTranslation } from 'next-i18next';
import { getDecryptEmail, queryEmail, sendParentalEmail } from '@/api/home';
import { Toast } from '@/components';
import { useRouter } from 'next/router';
import { traceEvent } from '@wk/wk-gatherer';
import { useMedia } from 'react-use';
interface IProps {
  open: boolean;
  onClose: () => void;
  notReload?: boolean;
}

type Step = 'input_email' | 'verify' | 'problem' | 'not_receive' | 'expire' | 'resend_email';

export default ({ open, onClose, notReload = false }: IProps) => {
  const isMobile = useMedia('(max-width: 720px)');
  const { t } = useTranslation('home');
  const router = useRouter();
  const [seconds, setSeconds] = useCount(0) as [number, Function];
  const { isParentVerify, updateParentalVerification, account, parentVerifyLoading } = useUser();
  const [errorString, setErrorString] = useState<string>('');
  const [emailString, setEmailString] = useState<string>('');
  const [step, setStep] = useState<Step>('input_email');
  const [loading, setLoading] = useState<boolean>(false);
  const sendRef = useRef<any>();
  const [problemName, setProblemName] = useState<string>();
  const [showAllEmail, setShowAllEmail] = useState<boolean>(false);
  const onInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    const email = e.target.value;
    setEmailString(email);
    if (email !== '' && !/^[a-zA-Z0-9_-]+@[a-zA-Z0-9_-]+(\.[a-zA-Z0-9_-]+)+$/.test(email)) {
      setErrorString('请输入有效的邮箱信息');
      return;
    }
    setErrorString('');
  };

  const verificationStatus = {
    finished: {
      text: t('自主化-家长验证-已通过'),
      src: 'checked',
    },
    unfinished: {
      text: t('自主化-家长验证-未通过'),
      src: 'message_hint',
    },
  };
  const commonEmail = useMemo(() => commonEmails.find((item) => emailString?.endsWith(item.suffix)), [emailString]);

  const callQueryEmailApi = useCallback(async () => {
    setLoading(true);
    const email = await queryEmail();
    if (email) {
      setStep('verify');
      setEmailString(email);
    }
    setLoading(false);
  }, []);

  useEffect(() => {
    if (open) {
      setShowAllEmail(false);
      setStep('input_email');
      callQueryEmailApi();
    }
  }, [open, callQueryEmailApi]);

  const sendEmail = async () => {
    setSeconds(30);
    await sendParentalEmail(await getDecryptEmail());
    Toast.success(t('自主化-邮箱验证-邮件发送成功'));
  };

  const onStep = () => {
    if (['not_receive', 'expire'].includes(step)) {
      setStep('problem');
    }
    if (step === 'resend_email') {
      setStep('not_receive');
    }
    if (step === 'problem') {
      setStep('verify');
    }
  };

  const problems = [
    {
      text: t('自主化-家长验证-没有收到验证邮件？'),
      step: 'not_receive',
    },
    {
      text: t('自主化-家长验证-验证邮件已过期'),
      step: 'expire',
    },
  ];
  const controlVisible = async () => {
    if (!showAllEmail) {
      setEmailString(await getDecryptEmail());
    } else {
      setEmailString(await queryEmail());
    }
    traceEvent({
      data: {
        _event: `U_ParentVerifyPop_ViewEmailClick`,
        account_id: account?.uuid,
      },
    });
    setShowAllEmail(!showAllEmail);
  };
  const renderbracketContent = (content: React.ReactElement, showRefresh?: boolean) => {
    return (
      <div className={style.bracketContent}>
        <>{i18n?.language === 'zh' ? '（' : '('}</>
        {showRefresh && (
          <img
            className={cls(style.refresh, parentVerifyLoading ? style.rotate : '')}
            src="/imgs/preparations/refresh.svg"
            alt=""
          />
        )}
        {content}
        <>{i18n?.language === 'zh' ? '）' : ')'}</>
      </div>
    );
  };
  const renderPostNewEmail = () => {
    if (step === 'input_email' || step === 'resend_email') {
      return (
        <div className={cls(style.modalBody)}>
          <p className={style.p1}>
            {step === 'resend_email' ? t('自主化-家长验证-请输入新的验证邮箱') : t('自主化-家长验证-保护孩子隐私安全')}
          </p>
          <div className={style.emailInputWrap}>
            <input
              type="text"
              className={style.inputEmail}
              onInput={onInput}
              placeholder={t('自主化-邮箱验证-请输入邮箱')}
            />
          </div>
          {errorString && <p className={style.errorString}>{errorString}</p>}
          <p
            className={style.p5}
            dangerouslySetInnerHTML={{
              __html: t('自主化-家长验证-儿童隐私'),
            }}
          ></p>
          <div className={style.sendEmailFooter}>
            <button
              className={cls(style.sendEmailBtn, emailString ? '' : style.disabledBtn)}
              onClick={async () => {
                await sendParentalEmail(emailString);
                callQueryEmailApi();
                traceEvent({
                  data: {
                    _event:
                      step === 'resend_email'
                        ? `U_ChangeEmailPop_SendEmailClick`
                        : `U_VerifyEmailPop_VerificationClick`,
                    account_id: account?.uuid,
                  },
                });
                Toast.success(t('自主化-邮箱验证-邮件发送成功'));
              }}
              disabled={!emailString}
            >
              {t('自主化-邮箱验证-立即验证')}
            </button>
          </div>
        </div>
      );
    }
    return null;
  };
  const renderTitle = () => {
    if (['verify', 'input_email'].includes(step)) {
      return t('自主化-儿童隐私保护');
    }
    return t('自主化-邮箱验证-验证遇到问题');
  };
  return (
    <WKModal open={open} radius={10} showClose={false} {...(isMobile ? { width: 'calc(100vw - 40px)' } : {})}>
      <div className={style.modalContent} ref={sendRef}>
        <div className={style.modalHeader}>
          <h3 className={style.h3}>
            {['problem', 'not_receive', 'expire', 'resend_email'].includes(step) && (
              <img onClick={onStep} src="/imgs/preparations/left_arrow.svg" alt="" />
            )}
            {renderTitle()}
          </h3>
          <img
            style={{ cursor: 'pointer' }}
            onClick={() => {
              updateParentalVerification();
              onClose?.();
              if (isParentVerify && !notReload) {
                let timer = setTimeout(() => {
                  window.location.reload();
                  clearTimeout(timer);
                }, 1000);
              }
            }}
            src="/imgs/preparations/close-popup.svg"
            alt=""
          />
        </div>
        {!loading && (
          <>
            {step === 'verify' && (
              <div className={cls(style.modalBody)}>
                {!isParentVerify ? (
                  <>
                    <p className={cls(style.p1, style.mb8)}>{t('自主化-家长验证-保护孩子隐私安全')}</p>
                    <section className={style.seeEmailWrap}>
                      <p
                        className={style.p2}
                        style={{ marginBottom: 0 }}
                        dangerouslySetInnerHTML={{
                          __html: t('自主化-家长验证-发一份邮件', {
                            email_address: emailString,
                          }),
                        }}
                      ></p>
                      <span className={style.seeEmail} onClick={controlVisible}>
                        <img src={`/imgs/preparations/eye-${!showAllEmail ? 'closed' : 'open'}.png`} alt="" />
                      </span>
                    </section>
                    <div className={style.detectDevice}>
                      <p className={style.parentalStatusTitle}>{t('自主化-家长验证-家长身份验证状态')}</p>
                      <div className={style.verificationConent}>
                        <div className={style.detectDeviceLeft}>
                          <img
                            src={`/imgs/preparations/${
                              verificationStatus[isParentVerify ? 'finished' : 'unfinished'].src
                            }.svg`}
                            alt=""
                          />
                          <span>
                            {verificationStatus[isParentVerify ? 'finished' : 'unfinished'].text}{' '}
                            {!isParentVerify &&
                              renderbracketContent(
                                <span
                                  className={style.reget}
                                  onClick={() => {
                                    updateParentalVerification(t('刷新成功'));
                                    traceEvent({
                                      data: {
                                        _event: `U_ParentVerifyPop_RefreshValidationStatusClick`,
                                        account_id: account?.uuid,
                                      },
                                    });
                                  }}
                                  dangerouslySetInnerHTML={{ __html: t('自主化-家长验证-点此刷新验证状态') }}
                                ></span>,
                                true,
                              )}
                          </span>
                        </div>
                        <div className={style.detectDeviceRight}>
                          {!isParentVerify && commonEmail && (
                            <WKButton
                              className={style.verifyBtn}
                              onClick={() => {
                                window.open(commonEmail.site, '_blank');
                              }}
                            >
                              {t('自主化-邮件验证-查看验证邮件')}
                            </WKButton>
                          )}
                        </div>
                      </div>
                    </div>
                    {!isParentVerify && (
                      <p className={style.repeatPost}>
                        {t('自主化-家长验证-没收到邮件或邮件已过期？')}
                        {renderbracketContent(
                          <span
                            onClick={() => {
                              setStep('problem');
                              traceEvent({
                                data: {
                                  _event: `U_ParentVerifyPop_VerificationProblemClick`,
                                  account_id: account?.uuid,
                                },
                              });
                            }}
                          >
                            {t('自主化-邮箱验证-没有收到邮件')}
                          </span>,
                        )}
                      </p>
                    )}
                  </>
                ) : (
                  <div className={style.verifySuccess}>
                    <div className={style.successHead}>
                      <img src="/imgs/preparations/check-circle-filled.png" alt="" />
                      <p>{t('自主化-家长验证-验证成功')}</p>
                    </div>
                    <p className={style.verifySuccessTitle}>{t('自主化-家长验证-已通过')}</p>
                    <div className={style.verifySuccessFooter}>
                      <button
                        className={cls(style.sendEmailBtn, emailString ? '' : style.disabledBtn)}
                        onClick={() => {
                          traceEvent({
                            data: {
                              _event: `U_ParentVerifyPop_ExperienceImmediatelyClick`,
                              account_id: account?.uuid,
                            },
                          });
                          onClose?.();
                          let timer = setTimeout(() => {
                            if (notReload) {
                              router.push('/home');
                            } else {
                              window.location.reload();
                            }
                            clearTimeout(timer);
                          }, 300);
                        }}
                        disabled={!emailString}
                      >
                        {t('自主化-邮箱验证-立即体验')}
                      </button>
                    </div>
                  </div>
                )}
              </div>
            )}
            {renderPostNewEmail()}
            {step === 'problem' && (
              <div className={cls(style.modalBody)}>
                <ul className={style.problemOptions}>
                  {problems.map((item) => {
                    return (
                      <li
                        key={item.step}
                        onClick={() => {
                          setProblemName(item.text);
                          setStep(item.step as Step);
                          if (item.step === 'not_receive') {
                            traceEvent({
                              data: {
                                _event: `U_VerifyProblemPop_NoEmailClick`,
                                account_id: account?.uuid,
                              },
                            });
                          }
                          if (item.step === 'expire') {
                            traceEvent({
                              data: {
                                _event: `U_VerifyProblemPop_PastEmailClick`,
                                account_id: account?.uuid,
                              },
                            });
                          }
                        }}
                      >
                        {item.text}
                      </li>
                    );
                  })}
                </ul>
              </div>
            )}
            {step === 'not_receive' && (
              <div className={cls(style.modalBody)}>
                <p className={style.p_p1}>{problemName}</p>
                <p className={style.p_p2}>1. {t('自主化-邮箱验证-请检查您的所有邮件，包括垃圾邮箱中的邮件信息')}</p>
                <p className={style.p_p2}>
                  2. {t('自主化-邮箱验证-试试重新发送邮件，点击此处')}
                  {seconds > 0 ? (
                    <span className={style.count_down}>
                      {t('自主化-家长验证-已重新发送').toLocaleLowerCase()} ({seconds}s)
                    </span>
                  ) : (
                    <span
                      onClick={() => {
                        traceEvent({
                          data: {
                            _event: `U_NoEmailPop_ResendEmailClick`,
                            account_id: account?.uuid,
                          },
                        });
                        sendEmail();
                      }}
                    >
                      {t('自主化-家长验证-重新发送邮件')}
                    </span>
                  )}
                </p>
                {!account?.email && (
                  <p className={style.p_p2}>
                    3. {t('自主化-邮箱验证-试试换一个邮箱，点击此处')}
                    <span
                      onClick={() => {
                        setEmailString('');
                        setStep('resend_email');
                        traceEvent({
                          data: {
                            _event: `U_NoEmailPop_ChangeEmailClick`,
                            account_id: account?.uuid,
                          },
                        });
                      }}
                    >
                      {t('自主化-家长验证-更换验证邮箱')}
                    </span>
                  </p>
                )}
              </div>
            )}
            {step === 'expire' && (
              <div className={cls(style.modalBody)}>
                <p className={style.p_p1}>{problemName}</p>
                <p className={style.p_p2}>
                  {t('自主化-邮箱验证-试试重新发送邮件，点击此处')}
                  {seconds > 0 ? (
                    <span className={style.count_down}>
                      {t('自主化-家长验证-已重新发送').toLocaleLowerCase()} ({seconds}s)
                    </span>
                  ) : (
                    <span
                      onClick={() => {
                        traceEvent({
                          data: {
                            _event: `U_PastEmailPop_ResendEmailClick`,
                            account_id: account?.uuid,
                          },
                        });
                        sendEmail();
                      }}
                    >
                      {t('自主化-家长验证-重新发送邮件')}
                    </span>
                  )}
                </p>
              </div>
            )}
          </>
        )}
      </div>
    </WKModal>
  );
};
