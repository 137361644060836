import { CommonEmail } from './types';

export const commonEmails: CommonEmail[] = [
  {
    suffix: '@gmail.com',
    site: 'https://mail.google.com/',
  },
  {
    suffix: '@outlook.com',
    site: 'https://outlook.live.com/',
  },
  {
    suffix: '@live.com',
    site: 'https://outlook.live.com/',
  },
  {
    suffix: '@hotmail.com',
    site: 'https://outlook.live.com/',
  },
  {
    suffix: '@yahoo.com',
    site: 'https://mail.yahoo.com/',
  },
  {
    suffix: '@icloud.com',
    site: 'https://www.icloud.com/mail',
  },
  {
    suffix: '@mail.com',
    site: 'https://www.mail.com/',
  },
  {
    suffix: '@zoho.com',
    site: 'https://www.zoho.com/mail/',
  },
  {
    suffix: '@protonmail.com',
    site: 'https://mail.protonmail.com/login',
  },
  {
    suffix: '@fastmail.com',
    site: 'https://www.fastmail.com/',
  },
  {
    suffix: '@qq.com',
    site: 'https://mail.qq.com/',
  },
  {
    suffix: '@163.com',
    site: 'https://mail.163.com/',
  },
  {
    suffix: '@126.com',
    site: 'https://qiye.163.com/login/',
  },
  {
    suffix: '@sohu.com',
    site: 'https://mail.sohu.com',
  },
  {
    suffix: '@21cn.com',
    site: 'http://mail.21cn.com/',
  },
  {
    suffix: '@139.com',
    site: 'http://mail.10086.cn/',
  },
  {
    suffix: '@sina.com',
    site: 'http://mail.sina.com.cn/',
  },
  {
    suffix: '@tom.com',
    site: 'http://mail.tom.com',
  },
  {
    suffix: '@yahoo.com',
    site: 'https://mail.yahoo.com',
  },
  {
    suffix: '@yandex.com',
    site: 'https://mail.yandex.com/',
  },
  {
    suffix: '@mail.ru',
    site: 'https://e.mail.ru/',
  },
  {
    suffix: '@gmx.com',
    site: 'https://www.gmx.com/',
  },
  {
    suffix: '@t-online.de',
    site: 'https://www.t-online.de',
  },
  {
    suffix: '@tutanota.com',
    site: 'https://mail.tutanota.com/',
  },
  {
    suffix: '@naver.com',
    site: 'https://www.naver.com/',
  },
  {
    suffix: '@daum.net',
    site: 'https://www.daum.net/',
  },
  {
    suffix: '@aol.com',
    site: 'https://www.mail.aol.com/',
  },
];
